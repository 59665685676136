@use 'settings' as *;
@use 'helper' as *;

// html
html {
    overflow-x: hidden !important;
}

// body
body {

    position: relative;
    margin: 0;
    padding: 0;

    font-family: $body_font_family;
    font-weight: $body_font_weight;
    font-size: $body_font-size;

    background: $body_bg;
    color: $body_color;

    @include md {
        font-size: $body_font_size_ipad;
    }

    @include lg {
        font-size: $body_font_size_desktop;
    }

}

// paragrapgh
p {

    font-family: $body_font_family;
    font-weight: $body_font_weight;
    font-size: $body_font-size;

    color: $body_color;

    margin-bottom: $paragraph_margin;

    @include md {
        font-size: $body_font_size_ipad;
    }

    @include lg {
        font-size: $body_font_size_desktop;
    }

}

// links
a {
    color: $body_color;
}

// strong
strong {
    font-weight: setfont('weight', 'regular');
}

// headings
h1, h2, h3, h4, h5, h6 {
    padding: 0;
    margin: 0;
}

h1 {
    margin-bottom: $h1_margin;
}

h2 {
    margin-top: $h2_margin;
    margin-bottom: $h2_margin;
    font-size: rem(24);
    @include md {
        font-size: rem(32);
    }
    @include md {
        font-size: rem(42);
    }
}

h3 {
    margin-top: $h3_margin;
    margin-bottom: $h3_margin;
    font-size: rem(20);
    @include md {
        font-size: rem(24);
    }
    @include md {
        font-size: rem(30);
    }
}

h4 {
    margin-top: $h4_margin;
    margin-bottom: $h4_margin;
    font-size: rem(20);
    @include md {
        font-size: rem(24);
    }
    @include md {
        font-size: rem(30);
    }
}

h5 {
    margin-top: $h5_margin;
    margin-bottom: $h5_margin;
}

h6 {
    margin-top: $h6_margin;
    margin-bottom: $h6_margin;
}