// Header
@use 'settings' as *;
@use 'helper' as *;
@use 'tools' as *;

.o-promo-contact {

    @extend %margin_horizontal_main;

    margin-top: rem(30);
    margin-bottom: rem(30);

    @include md {
        margin-top: rem(60);
        margin-bottom: rem(60);
    }

}