// Object

// Layout
@use 'header';
@use 'footer';

// Components
@use 'module';
@use 'legal';

// Components
@use 'banner';
@use 'block';
@use 'breadcrumbs';
@use 'carousel';
@use 'contact';
@use 'features';
@use 'gallery';
@use 'hero';
@use 'hitos';
@use 'icons';
@use 'intro';
@use 'map';
@use 'promo-contact';
@use 'promotions';
@use 'services';
@use 'two_columns';

#wrapper {
    @media screen and (min-width: 2000px){
        max-width: 1800px;
        margin: 0 auto;
        background-color: #eee;
        box-shadow: 0 0 30px rgba($color: #000000, $alpha: 0.2);
        border-left: 10px solid white;
        border-right: 10px solid white;
    }
}