// Header
@use 'settings' as *;
@use 'helper' as *;
@use 'tools' as *;

.c-form2__row {
    display: flex;
    margin: 0 -15px;
    margin-bottom: rem(20);
}

.c-form2__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: rem(30);
}

.c-form2__checkbox {
    display: flex;
    align-items: center;
    line-height: 1em;
    input {
        margin-right: rem(10);
    }
}

form {

    input[type="text"],
    input[type="email"] {

        background-color: transparent;
        border: none;
        border-bottom: 1px solid setcolor('gray');
        height: 40px;
        width: 100%;
        margin: 0 rem(15);

        font-size: rem(14);
        line-height: 1.1;

        @include md {
            font-size: rem(16);
        }
        @include lg {
            font-size: rem(18);
        }


        &:focus, &:active {
            outline: none;
        }
    }

    input[type="checkbox"] {
        background-color: transparent;
    }

    textarea {
        background-color: transparent;
        border: none;
        border: 1px solid var(--c-primary);
        font-size: 17px;
        padding: 15px 30px;
        width: 100%;
    }

    button[type="submit"] {
        // border: none;
        // height: 44px;
        // background-color: var(--c-primary);
        // font-size: 22px;
        // color: white;
    }

}