// Header
@use 'settings' as *;
@use 'helper' as *;
@use 'tools' as *;

.splide__arrow {

    background: transparent;
    opacity: 1;

    &--prev {
        left: 0;
        transform: translate(-100%, -50%);
    }

    &--next {
        right: 0;
        transform: translate(100%, -50%);
    }

}