// Header
@use 'settings' as *;
@use 'helper' as *;
@use 'tools' as *;

.o-promotions {

    // items
    &__items {

        @extend %max-width;

        display: flex;
        flex-direction: column;

        @include md {
            flex-direction: row;
            justify-content: center;
        }

    }

    // item
    &__item {

        position: relative;
        margin: rem(10) 0;

        @include md {
            margin: 0 rem(10);
        }

        &:before {
            @include dark-layer;
            transition: all .3s;
        }

        &:hover:before {
            opacity: 0.5;
        }

        &__overlay {
            position: absolute;
            z-index: 300;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            span {
                position: absolute;
                bottom: rem(15);
                left: 50%;
                transform: translateX(-50%);
                white-space: nowrap;
                color: setcolor('white');
            }
        }
    }

}