// Module

@use 'settings' as *;
@use 'helper' as *;
@use 'tools' as *;

// header
.o-module-header {

    position: relative;
    z-index: 100;

    @extend %margin_horizontal_main;
    @extend %margin_bottom_small;

    text-align: center;

    // image
    img {
        margin-bottom: rem(15);
    }

    // text
    &__text {
        margin-top: rem(25);
        @include xl {
            max-width: 80%;
            margin: 0 auto;
            margin-top: rem(40);
        }
    }

    // max-width
    &--max-width {
        @extend %max-width;
    }

}