// Services

@use 'settings' as *;
@use 'helper' as *;
@use 'tools' as *;

.o-icons {

  @extend %max-width-big;

  // fila
  &__row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @include md {
      flex-wrap: nowrap;
      margin-bottom: rem(40);
    }
  }

  // item
  &__item {

    text-align: center;
    padding: 0 rem(15) rem(15) rem(15);

    width: 50%;

    @include md {
      width: inherit;
      margin: 0 rem(15) 0 rem(15);
    }

    @include lg {
      margin: 0 rem(30) 0 rem(30);
    }

    @include xxl {
      margin: 0 rem(50) 0 rem(50);
    }

    // image
    img {
      margin-bottom: rem(10);
      @include md {
        margin-bottom: rem(20);
      }
    }

  }

}