// Features
@use 'settings' as *;
@use 'helper' as *;
@use 'tools' as *;

.o-legal {

    ul {
        padding-left: rem(25);
    }

    ul li {
        list-style: disc;
    }

    ol li {
        list-style: decimal;
    }

    h2, h3, h4 {
        margin: rem(30) 0;
        font-weight: bold;
    }

}