// Services

@use 'settings' as *;
@use 'helper' as *;
@use 'tools' as *;

.o-breadcrumbs {

    @extend %margin_horizontal_main;

    display: flex;
    align-items: center;

    a, span {
        color: setcolor('primary','dark');
        margin-right: rem(10);
    }

}