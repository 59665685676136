// Header
@use 'settings' as *;
@use 'helper' as *;
@use 'tools' as *;

.o-contact {

    @extend %margin_horizontal_main;

    position: relative;

    display: flex;
    flex-direction: column;

    @include lg {
        flex-direction: row;
        align-items: center;
    }

    // header
    .o-module-header {
        @include lg {
            width: 40%;
            padding: 0 rem(120) 0 0;
            text-align: left;
        }
        @include xxl {
            width: 35%;
            padding: 0 rem(240) 0 0;
        }
    }

    // formulario
    &__form {
        background-color: setcolor('gray','light');
        padding: rem(40);
        @include md {
            padding: rem(40) rem(60);
        }
        @include lg {
            padding: rem(75) rem(100);
            width: 60%;
        }
        @include xxl {
            width: 65%;
        }
    }

}