// Services
@use 'settings' as *;
@use 'helper' as *;
@use 'tools' as *;

.o-two_cols {

  @extend %max-width-big;

  display: flex;
  flex-direction: column;

  @include md {
    flex-direction: row;
    align-items: center;
  }

  &__col {
    margin: 0 0 rem(50);
    @include md {
      margin: 0 rem(20);
      width: 50%;
    }
    @include xl {
      margin: 0 rem(40);
    }
  }

}