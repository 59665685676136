@use 'helper/size' as *;

:root {

    --horizontal-main-xs: #{rem(30)};
    --horizontal-main-sm: #{rem(30)};
    --horizontal-main-md: #{rem(40)};
    --horizontal-main-lg: #{rem(70)};
    --horizontal-main-xl: #{rem(100)};
    --horizontal-main-xxl: #{rem(150)};

    --horizontal-large-xs: #{rem(50)};
    --horizontal-large-sm: #{rem(50)};
    --horizontal-large-md: #{rem(80)};
    --horizontal-large-lg: #{rem(120)};
    --horizontal-large-xl: #{rem(220)};
    --horizontal-large-xxl: #{rem(340)};

    --vertical-small-xs: #{rem(35)};
    --vertical-small-sm: #{rem(35)};
    --vertical-small-md: #{rem(50)};
    --vertical-small-lg: #{rem(50)};
    --vertical-small-xl: #{rem(70)};
    --vertical-small-xxl: #{rem(70)};

    --vertical-medium-xs: #{rem(60)};
    --vertical-medium-sm: #{rem(60)};
    --vertical-medium-md: #{rem(80)};
    --vertical-medium-lg: #{rem(80)};
    --vertical-medium-xl: #{rem(120)};
    --vertical-medium-xxl: #{rem(120)};

    --vertical-large-xs: #{rem(80)};
    --vertical-large-sm: #{rem(80)};
    --vertical-large-md: #{rem(100)};
    --vertical-large-lg: #{rem(100)};
    --vertical-large-xl: #{rem(160)};
    --vertical-large-xxl: #{rem(160)};

}