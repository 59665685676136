// SETTINGS /  COLORS
@use 'sass:map';

// Colores
$colors: (
  "primary": (
    base: #CBCBCB,
    light: #E2E2E2,
    dark: #7F7F7F
  ),
  "secondary": (
    base: #1D1D1B,
  ),
  "gray": (
    base: #CBCBCB,
    light: #F5F5F5,
    dark: #3D3D3B
  ),
  "black": (
    base: #1D1D1B,
  ),
  "white": (
    base: #ffffff,
  ),
);

// Funcion para extraer color y tono
// Pones 'setcolor("color", variante)';
@function setcolor($scheme, $tone: base) {
    @return map.get($colors, $scheme, $tone);
}