// Features
@use 'settings' as *;
@use 'helper' as *;
@use 'tools' as *;

.o-features {

    position: relative;

    @extend %margin_horizontal_main;

    &__wrap {
        display: flex;
        flex-direction: column;

        @include lg {
            flex-direction: row;
            align-items: center;
        }
    }

    // decoration
    &:before {
        display: none;
        content: '';
        position: absolute;
        z-index: 0;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        height: rem(300);
        background-color: setcolor('gray','light');
        @include md {
            display: block;
        }
    }

    // header
    .o-module-header {
        @include lg {
            width: 20%;
            padding: 0;
        }
    }

    // items
    &__items {
        position: relative;
        z-index: 100;
        display: flex;
        flex-direction: column;
        margin: rem(30) 0 0 0;
        @include md {
            flex-direction: row;
            flex-wrap: wrap;
            margin: 0;
        }
    }

    // columnas
    &__columns {
        display: flex;
        flex-direction: row;
        flex-direction: column;
        width: 100%;
        @include md {
            flex-direction: column;
            width: 33%;
            padding: 0 0 0 rem(30);
            &:nth-child(2) {
                margin-top: rem(30);
            }
            &:nth-child(3) {
                margin-top: rem(60);
            }
        }
        @include xl {
            padding: 0 0 0 rem(30);
        }
    }

    // item
    &__item {
        padding: rem(25);
        background-color: setcolor('white');
        box-shadow: rem(5) rem(5) rem(8) rgba($color: #000000, $alpha: 0.15);
        text-align: center;
        margin: 0 0 rem(35);
    }

    // item header
    &__header {

        margin-bottom: rem(15);
        font-size: rem(16);
        font-weight: setfont('weight','bold');
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        @include xxl {
            font-size: rem(18);
            text-align: left;
            flex-direction: row;
            max-width: 80%;
            margin: 0 auto;
            margin-bottom: rem(10);
        }

    }

}