html{
    margin                                  : 0;
    padding                                 : 0 !important;
}

body {
    margin                                  : 0;
    padding                                 : 0 !important;
    width                                   : 100%;
}

h1,h2, h3, h4, h5, h6{
    margin                                  : 0;
    padding                                 : 0;
}

p {
    margin                                  : 0;
}

ul {
    margin                                  : 0;
    padding                                 : 0;
}

li {
    margin                                  : 0;
    padding                                 : 0;
    list-style                              : none;
}