// HELPERS / COLORS
@use 'settings' as *;
@use 'sass:map';

/* Color Class */
@each $item, $color in  $colors {
    @each $type, $value in $color {
      .color--#{$item}-#{$type} {
        background-color: $value;
      }
    }
}