// Header
@use 'settings' as *;
@use 'helper' as *;
@use 'tools' as *;

.o-header {

    @extend %margin_horizontal_main;

    padding-top: rem(15);
    padding-bottom: rem(15);

    display: flex;
    justify-content: space-between;
    align-items: center;

    @include md {
        padding-top: rem(30);
        padding-bottom: rem(30);
    }

    @include xl {
        padding-top: rem(40);
        padding-bottom: rem(40);
    }

    // brand 
    &__brand img {
        max-width: rem(130);
        @include md {
            max-width: rem(220);
        }
        @include xl {
            max-width: rem(300);
        }
    }

    // menu
    &__menu {
        display: flex;
        li {
            margin-left: rem(10);
            @include md {
                margin-left: rem(25);
            }
            @include xl {
                margin-left: rem(40);
            }
            &.menu-current a {
                border-bottom: rem(4) solid setcolor('gray');
            }
        }
        a {

            font-size: rem(12);
            color: setcolor('gray','dark');   

            padding: rem(5) 0;
            border-bottom: rem(4) solid transparent;
            
            @include md {
                font-size: rem(14);
            }

            @include xl {
                font-size: rem(18);
                padding: rem(10) 0;
            }
            
        }
    }
}