// HELPERS / FONT
@use 'settings' as *;
@use 'size' as *;
@use 'sass:map';
@use 'sass:list';

/* Font Family Class */
@each $name-font, $family in map.get($fonts, "family") {
    .font--#{$name-font}{
        font-family: $family;
    }
}

/* Font Weight Class */
@each $name-font, $family in map.get($fonts, "weight") {
    .font--#{$name-font}, .font--#{$name-font} p {
        font-weight: $family;
    }
}

/* Font Size Class */
@each $name-font, $family in map.get($fonts, "size") {
    .font--#{$name-font}{
        font-size: rem( list.nth($family, 1) );
        @include md {
            font-size: rem(list.nth($family, 2));
        }
        @include lg {
            font-size: rem(list.nth($family, 3));
        }
        @include xl {
            font-size: rem(list.nth($family, 4));
        }
        @include xxl {
            font-size: rem(list.nth($family, 5));
        }
    }
}