// Header
@use 'settings' as *;
@use 'helper' as *;
@use 'tools' as *;

.o-footer {

  @extend %margin_horizontal_main;

  color: setcolor('white'); 
  @extend .font--h6;

  background: setcolor('gray','dark');    

  padding-top: rem(40);
  padding-bottom: rem(40);

  text-align: center;

  @include md {

    padding-top: rem(40);
    padding-bottom: rem(40);

    text-align: left;

  }

  @include xl {
    padding-top: rem(60);
    padding-bottom: rem(60);
  }

  // text
  p, a {
    color: setcolor('white'); 
    @extend .font--h6;
    display: block;
  }

  // titles
  h5 {
    font-weight: setfont('weight','bold');
    margin-bottom: rem(8);
  }

  // brand
  &__brand {

    margin: 0 0 rem(25);

    @include md {
      margin: 0 rem(25) 0 0;
    }
    @include lg {
      margin: 0 rem(75) 0 0;
    }
    @include xl {
      margin: 0 rem(150) 0 0;
    }

    img {
      max-width: rem(220);
      @include lg {
        max-width: rem(180);
      }
      @include xl {
        max-width: rem(300);
      }
    }
  }

  // top
  &__top {
    display: flex;
    flex-direction: column;
    @include md {
      flex-direction: row;
    }
  }

  // bottom
  &__bottom {

    margin-top: rem(25);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    @include md {
      flex-direction: row;
    }

    // menu
    ul {
      display: flex;
      flex-direction: column;
      text-align: center;
      margin-top: rem(10);
      @include md {
        flex-direction: row;
        margin-top: 0;
      }
      li {
        @include md {
          margin-left: rem(10);
          padding-left: rem(10);
          border-left: rem(1) solid setcolor('white');
        }
        @include lg {
          margin-left: rem(20);
          padding-left: rem(20);
        }
        @include xl {
          margin-left: rem(50);
          padding-left: rem(50);
        }
        &:first-child {
          margin: 0;
          padding: 0;
          border: 0;
        }
      }
    }

  }

  // contact
  &__contact {

    display: flex;
    flex-direction: column;
    @include md {
      flex-direction: row;
      flex-wrap: wrap;
    }
    @include xl {
      width: 100%;
      justify-content: space-between;
    }

    &__item {
      margin-bottom: rem(25);
      @include md {
        width: 50%;
      }
      @include xl {
        width: auto;
      }
    }

  }

  // redes
  &__redes {
    display: flex;
    flex: row;
    justify-content: center;
    @include md {
      justify-content: flex-start;
    }
    @include lg {
      flex-direction: column;
    }
    li {
      margin: 0 rem(5);
      text-align: center;
      @include md {
        margin: 0 rem(10) 0 0;
      }
      @include xl {
        margin: 0 0 rem(10) 0;
      }
    }
  }

}