@use 'settings/colors' as *;
@use 'settings/fonts' as *;
@use 'helper/size' as *;
@use 'sass:map';

// colors
$body_bg: setcolor('white');
$body_color: setcolor('gray','dark');

// fonts
$body_font_size: rem(14);
$body_font_size_ipad: rem(16);
$body_font_size_desktop: rem(18);

$body_font_family: setfont("family", "content");
$body_font_weight: setfont("weight", "regular");

// paragraph and headings
$paragraph_margin: rem(20);
$h1_margin: rem(40);
$h2_margin: rem(40);
$h3_margin: rem(40);
$h4_margin: rem(40);
$h5_margin: rem(40);
$h6_margin: rem(40);

// buttons
$buttons_font_family: setfont("family", "secondary");
$buttons_font_weight: setfont('weight', 'regular');
$buttons_border_radius: 0;

$buttons_font_size: rem(14);
$buttons_font_size_ipad: rem(16);
$buttons_font_size_desktop: rem(18);

$buttons_padding: rem(15) rem(25) rem(10) rem(25);
$buttons_padding_ipad: rem(20) rem(30) rem(14) rem(30);
$buttons_padding_desktop: rem(20) rem(40) rem(12) rem(40);

// inputs
$inputs_font_family: setfont("family", "secondary");
$inputs_font_weight: setfont('weight', 'regular');
$inputs_color: setcolor('secondary', 'dark');

$inputs_border_radius: 0;

$inputs_font_size: rem(14);
$inputs_font_size_ipad: rem(16);
$inputs_font_size_desktop: rem(16);

$inputs_padding: rem(10) 0;
$inputs_padding_ipad: rem(10) 0;
$inputs_padding_desktop: rem(10) 0;

// dark layer
$dark_layer_opacity: 0.25;

// max-width
$max_width: rem(950);
$max_width_large: rem(1300);
$max_width_padding: rem(15);
$max_width_padding_medium: rem(40);
$max_width_padding_large: rem(70);
