.o-map {
  height: 60vh;
  width: 100%;
}


// .o-map {

//     position: relative;
//     height: 60vh;
//     width: 100%;

//     @media (min-width: 768px) {
//       height: 100vh;
//     }


//     #map {
//         position: relative;
//         z-index: 0;
//         height: 60vh;
//         width: 100%;

//         @media (min-width: 768px) {
//           height: 100vh;
//         }
//     }

//     .c-map__wrapper {
//         display: none;
//         position: absolute;
//         z-index: 1;
//         top: 50%;
//         transform: translateY(-50%);
//         right: 30px;
//         padding: 50px 0;
//         width: 400px;
//         background-color: rgba(169, 144, 84, 0.8);
//         flex-direction: column;
//         justify-content: space-between;

//         @media (min-width: 768px) {
//           display: flex;
//           width: 300px;
//           padding: 20px 0;
//         }
//         @media (min-width: 1024px) {
//           width: 400px;
//           padding: 25px 0;
//         }
//         @media (min-width: 1200px) {
//           padding: 30px 0;
//         }
//         @media (min-width: 1600px) {
//           padding: 50px 0;
//         }

//         .c-map__item {
//           display: flex;
//           flex-direction: column;
//           justify-content: center;
//           align-items: center;

//           p {
//             color: black;
//             text-align: center;
//             font-size: 14px;
//           }
//         }
//         .c-map__item:not(.c-map__item:last-of-type) {
//           padding-bottom: 20px;
//         }
//       }
// }

