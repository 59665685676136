// Services

@use 'settings' as *;
@use 'helper' as *;
@use 'tools' as *;

.o-blocks {

    // items
    &__items {

        @extend %margin_horizontal_main;

        display: flex;
        flex-wrap: wrap;

        img {
            width: 100%;
            height: auto;
        }

    }

    // item
    &__item {

        width: 50%;
        padding: rem(5);

        @include lg {
            width: 25%;
            padding: 0 rem(5);
            &:nth-child(2) {
                margin-top: rem(15);
            }
            &:nth-child(3) {
                margin-top: rem(30);
            }
            &:nth-child(4) {
                margin-top: rem(45);
            }
        }

    }

}