// Header
@use 'settings' as *;
@use 'helper' as *;
@use 'tools' as *;

.o-banner {

  position: relative;
  z-index: 0;
  width: 100%;

  // full
  &--full {
    height: calc(100vh - 58px);
    @include md {
      height: calc(100vh - 107px);
    }
    @include xl {
      height: calc(100vh - 145px);
    }
  }

  // small
  &--small {
    height: 50vh;
  }

  // carousel
  .splide,
  .splide__track,
  .splide__slide {
    width: 100%;
    height: 100%;
  }

  .splide__arrow {

    display: none;
    // &--prev {
    //   left: 0;
    //   transform: translate(50%, -50%);
    // }
    // &--next {
    //   right: 0;
    //   transform: translate(-50%, -50%);
    // }
    // svg {
    //   fill: setcolor('white');
    // }
  }

  // background
  &__bg {
    height: 100%;
    width: 100%;
    overflow: hidden;
    img {
      @include image-cover;
    }
    &:before {
      @include dark-layer;
    }
  }

  // content
  &__content {
    @extend %margin_horizontal_main;
    position: absolute;
    z-index: 300;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    @include md {
      max-width: 80%
    }
    @include lg {
      max-width: 70%
    }
  }

  &__title {
    @extend .font--h1;
    color: setcolor('white');
    margin-bottom: rem(5);
    strong {
      font-weight: setfont('weight','bold');
    }
  }

  &__subtitle {
    @extend .font--h2;
    color: setcolor('white');
  }

  .o-banner__wrapper {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    padding: 0;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    @media (min-width: 768px) {
      top: 0;
      right: 120px;
      width: 380px;
      padding: 70px 0;
    }
    @media (min-width: 1920px) {
      right: 5%;
    }

    .o-banner__logo {
      position: relative;
      top: 10px;
      background-color: white;
      width: 100%;

      display: flex;
      justify-content: center;

      @media (min-width: 768px) {
        top: -20px;
      }

      img {
        width: 320px;
        object-fit: contain;
        padding-bottom: 50px;
      }
    }

    .o-banner__form {
      position: relative;
      margin-bottom: 0;
      background-color: var(--c-primary);
      padding: 20px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      height: 60vh;

      @media (min-width: 768px) {
        margin-bottom: 50px;
        height: auto;
      }

      .o-banner__content {
        width: 100%;
      }

      .o-banner__title, .o-banner__cta {
        text-align: center;
        line-height: 1em;
        margin: 0 auto;

        color: white;
      }

      .o-banner__title {
        padding-bottom: 20px;
        max-width: 80%;
      }

      .o-banner__cta {
        background-color: var(--c-secondary);
        margin-bottom: 10px;
        padding: 10px 0;
        width: 100%;
      }

      form {
        margin-left: 15px;
        margin-right: 15px;

        .c-form__title {
          text-align: center;
          color: white;
        }

        .c-form1__row {
          display: flex;

          input {
            width: 50%;
            color: white;
            border-bottom: 1px solid white;

            &:first-of-type {
              margin-right: 30px;
            }
          }
        }

        .c-form1__wrapper {
          padding-top: 10px;

          .c-form1__checkbox {
            display: flex;
            padding-bottom: 10px ;

            .c-form1__policy {
              padding-left: 5px;
              padding-bottom: 3px;
              color: white;
            }
          }

          button {
            width: 40%;
            text-align: center;
            background-color: var(--c-secondary);
            float: right;

            @media (min-width: 768px) {
              width: 50%;
            }
          }
        }
      }
    }


  }
}
