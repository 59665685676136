// Header
@use 'settings' as *;
@use 'helper' as *;
@use 'tools' as *;

.o-gallery {

    @extend %margin_horizontal_main;

    &__image {
        width: 100%;
        height: auto;
        position: relative;
        z-index: 100;
    }

}