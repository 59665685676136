// Features
@use 'settings' as *;
@use 'helper' as *;
@use 'tools' as *;

.o-intro {

    @extend %margin_horizontal_main;
    position: relative;

    // decoration
    &:before {
        display: none;
        content: '';
        position: absolute;
        z-index: 0;
        left: 0;
        top: 0;
        transform: none;
        width: 100%;
        height: 60%;
        background-color: setcolor('gray','light');
        @include md {
            display: block;
        }
        @include lg {
            height: rem(400);
        }
    }

    // content
    &__content {

        position: relative;
        z-index: 100;

        display: flex;
        flex-direction: column;
        align-items: center;

        margin-bottom: rem(30);

        text-align: center;

        @include md {

            flex-direction: row;
            align-items: center;

            margin-bottom: rem(60);

            text-align: left;

        }
    }

    // header
    .o-module-header {
        @include lg {
            width: 40%;
            padding: 0 rem(100) 0 0;
        }
        @include xl {
            width: 20%;
            padding: 0 rem(160) 0 0;
        }
    }

    // image
    img {
        position: relative;
        z-index: 100;
    }

}