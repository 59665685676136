// Header
@use 'settings' as *;
@use 'helper' as *;
@use 'tools' as *;

.o-carousel {

    position: relative;

    @extend %margin_horizontal_main;

    @include md {
        padding-left: rem(30);
        padding-right: rem(30);
    }

    // wrap
    &__wrap {
        background-color: setcolor('white');
        box-shadow: 0 0 rem(20) rgba($color: #000000, $alpha: 0.05);
        padding: rem(30) rem(30);
        position: relative;
        z-index: 100;
        @include md {
            padding: rem(30) rem(50);
        }
        @include lg {
            padding: rem(30) rem(50);
        }
    }

    // decoration
    &:before {
        display: none;
        content: '';
        position: absolute;
        z-index: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 50%;
        background-color: setcolor('white');
        @include md {
            display: block;
        }
    }

    // splide item
    .splide__slide {

        width: 100%;
        padding: 0 rem(10);

        @include md {
            width: 50%;
            padding: 0 rem(15);
        }
        @include lg {
            width: 33%;
        }
        @include xl {
            width: 25%;
        }
        @include xxl {
            width: 20%;
        }
    }

    // image
    // img {
    //     height: 100%;
    //     width: 100%;
    //     object-fit: cover;
    // }

    // link
    a {
        display: block;
        position: relative;
        // height: 100%;
        &:before {
            @include dark-layer;
            transition: all 0.3s;
            opacity: 0;
        }
        &:hover {
            &:before,
            .splide-carousel__title  {
                opacity: 1;
            }
        }
    }

    // titulo
    .splide-carousel__title {

        position: absolute;
        z-index: 100;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 100%;
        padding: 0 rem(20);
        text-align: center;

        font-size: rem(18);
        font-weight: setfont('weight','bold');
        color: setcolor('white');

        transition: all 0.3s;
        opacity: 0;

        @include md {
            font-size: rem(20);
        }

        @include lg {
            font-size: rem(25);
        }

    }

    // splide text
    .splide__text {
        @include md {
            width: 25%;
        }
        @include lg {
            width: 17%;
        }
        @include xl {
            width: 12%;
        }
        @include xxl {
            width: 10%;
        }
        p {
            font-size: rem(11);
        }
    }

}