// SETTINGS / BREAKPOINTS
@use 'sass:map';

$breakpoints: (
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1600,
);

@mixin screen($breakpoint) {
  @if map_has-key($breakpoints, $breakpoint) {
    @media screen and (min-width: map_get($breakpoints, $breakpoint)) {
      @content;
    }
  } @else {
    @media screen and (min-width: $breakpoint) {
      @content
    }
  }
};

@mixin sm {
  @media only screen and (min-width: 576px) {
    @content;
  }
}

@mixin md {
  @media only screen and (min-width: 768px) {
    @content;
  }
}

@mixin lg {
  @media only screen and (min-width: 992px) {
    @content;
  }
}

@mixin xl {
  @media only screen and (min-width: 1200px) {
    @content;
  }
}

@mixin xxl {
  @media only screen and (min-width: 1600px) {
    @content;
  }
}