// HELPERS / COLORS
@use 'settings' as *;
@use 'sass:map';

/* Background Class */
@each $item, $color in  $colors {
    @each $type, $value in $color {
      .bg--#{$item}-#{$type} {
        background-color: $value;
      }
    }
}

.bg--middle-gray {

  position: relative;
  
  // decoration
  &:before {
    display: none;
    content: '';
    position: absolute;
    z-index: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 45%;
    background-color: setcolor('gray','light');
    @include md {
        display: block;
    }
  } 

}