@use 'settings' as *;

%max-width {
    padding-left: $max_width_padding;
    padding-right: $max_width_padding;
    @include md {
        padding-left: $max_width_padding_medium;
        padding-right: $max_width_padding_medium;
    }
    @include lg {
        padding-left: $max_width_padding_large;
        padding-right: $max_width_padding_large;
    }
    @include xl {
        max-width: $max_width;
        padding-left: 0;
        padding-right: 0;
        margin: 0 auto;
    }
}

%max-width-big {
    padding-left: $max_width_padding;
    padding-right: $max_width_padding;
    @include md {
        padding-left: $max_width_padding_medium;
        padding-right: $max_width_padding_medium;
    }
    @include lg {
        padding-left: $max_width_padding_large;
        padding-right: $max_width_padding_large;
    }
    @include xl {
        max-width: $max_width;
        padding-left: 0;
        padding-right: 0;
        margin: 0 auto;
    }
    @include xl {
        max-width: $max_width_large;
    }
}