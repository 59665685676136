// Services

@use 'settings' as *;
@use 'helper' as *;
@use 'tools' as *;

.o-hitos {

    background: url(../../../dist/images/hitos-bg.jpg);
    background-size: cover;

    color: setcolor('white');

    // items
    &__items {

        display: flex;
        flex-wrap: wrap;

        @extend %margin_horizontal_main;

    }

    // item
    &__item {
        text-align: center;
        width: 50%;
        padding: rem(10);
        @include md {
            padding: rem(40);
        }
        @include lg {
            width: 25%;
            padding: 0 rem(20);
        }
        @include xl {
            padding: 0 rem(20);
        }
    }

    // valores
    &__value {
        aspect-ratio: 1/1;
        border: rem(2) solid setcolor('white');
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-radius: 100%;
        margin: 0 auto;
        margin-bottom: rem(30);
        max-width: rem(290);

        span {
            font-size: rem(13);
            @include md {
                font-size: rem(24);
            }
            @include lg {
                font-size: rem(20);
            }
            @include xl {
                font-size: rem(24);
            }
            &:last-child {
                font-weight: bold;
                font-size: rem(16);
                @include md {
                    font-size: rem(32);
                }
                @include lg {
                    font-size: rem(24);
                }
                @include xl {
                    font-size: rem(30);
                }
            }
        }
    }

    &__text {
        font-size: rem(12);
        @include md {
            font-size: rem(14);
        }
        @include xl {
            font-size: rem(18);
        }
    }

}