// Services
@use 'settings' as *;
@use 'helper' as *;
@use 'tools' as *;

.o-services {

    @extend %margin_horizontal_main;
    @include md {
        padding-left: rem(30);
        padding-right: rem(30);
    }

    // items
    &__items {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    // item
    &__item {

        font-size: rem(18);
        font-weight: setfont('weight','bold');
        color: setcolor('primary','dark');

        position: relative;
        padding: rem(10) 0;

        width: 50%;

        transition: all .3s;

        @include md {
            padding: rem(20);
            font-size: rem(20);
        }

        @include lg {
            font-size: rem(25);
            padding: 0 rem(15);
            width: 25%;
        }

        // image
        img {
            border: rem(5) solid transparent;
            transition: all .3s;
            border-radius: rem(3);
            margin-bottom: rem(15);
            opacity: 0.6;
            @include md {
                margin-bottom: rem(30);
            }
        }

        // hover
        &:hover {

            color: setcolor('black');
            text-decoration: none;

            img {
                border: rem(5) solid setcolor('white');
                box-shadow: 0 0 5px rgba($color: #000000, $alpha: 0.3);
                opacity: 1;
            }
        }

    }

}